import * as React from "react";
const TronIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={156}
    height={139}
    viewBox="0 0 156 139"
    fill="none"
    {...props}
  >
    <path
      d="M134.281 36.746c0-.09-.048-.162-.054-.251a2.92 2.92 0 0 0 0-.648 2.929 2.929 0 0 0-.214-.457 3.114 3.114 0 0 0-.171-.368 2.688 2.688 0 0 0-1.016-1.01L100.73 17.27a2.464 2.464 0 0 0-1.177-.268H24.621c-.075 0-.134.05-.208.056a2.588 2.588 0 0 0-.883.24 2.147 2.147 0 0 0-.3.15c-.33.206-.611.486-.823.82 0 0-.043.034-.06.056a2.786 2.786 0 0 0-.347 1.2v.352c.009.312.07.62.182.91 0 .072 0 .15.048.223l48.143 100.45c.032.073.107.106.15.173.155.269.354.507.589.703.083.082.172.157.267.224.345.235.74.38 1.15.424.075.005.15.005.225 0a2.595 2.595 0 0 0 1.107-.274 2.92 2.92 0 0 0 .278-.184c.232-.151.44-.34.615-.558.043-.056.113-.078.155-.139l58.842-83.71a2.81 2.81 0 0 0 .466-1.294c.016-.005.064-.033.064-.078Zm-56.98 12.74L34.276 22.555h60.238L77.301 49.486Zm-1.99 5.263-4.419 55.32-39.771-82.983 44.19 27.663Zm5.317.625 44.099-13.8-48.512 69.009 4.413-55.209Zm43.746-19.532-39.91 12.506 15.961-24.984 23.949 12.478Z"
      fill="url(#a)"
    />
    <defs>
      <linearGradient
        id="a"
        x1={23.154}
        y1={68.701}
        x2={134.282}
        y2={68.815}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
    </defs>
  </svg>
);
export default TronIcon;
