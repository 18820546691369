import * as React from "react";
const SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Minery_report"
    viewBox="0 0 300 100"
    {...props}
  >
    <defs>
      <style>{".cls-1{fill:#fff}"}</style>
    </defs>
    <path
      className="cls-1"
      d="M68.75 44.03h3.6l2.93 4.76 2.94-4.76h3.6v12.29h-3.39v-7.05l-3.14 4.81h-.07l-3.11-4.78v7.02h-3.36V44.03zm20.58 0h3.42v12.29h-3.42zm10.92 0h3.19l5.07 6.5v-6.5h3.35v12.29h-2.99l-5.26-6.74v6.74h-3.36V44.03zm19.06 0h9.86v2.9h-6.51v1.86h5.9v2.69h-5.9v1.95h6.6v2.89h-9.95V44.03zm16.98 0h5.81c.95 0 1.75.12 2.41.35.66.23 1.19.56 1.6.98.73.7 1.09 1.66 1.09 2.86v.04c0 .95-.23 1.73-.68 2.35-.46.62-1.07 1.09-1.84 1.42l2.93 4.28h-3.93l-2.48-3.72h-1.49v3.72h-3.41V44.02Zm5.65 5.9c.59 0 1.04-.13 1.35-.4.32-.26.47-.62.47-1.06v-.03c0-.49-.16-.86-.49-1.1-.33-.24-.78-.36-1.35-.36h-2.23v2.95h2.25Zm15.79 1.79-4.67-7.69h3.88l2.53 4.48 2.54-4.48h3.81l-4.67 7.64v4.65h-3.42v-4.6zm25.7-7.69h5.16c1.51 0 2.72.47 3.46 1.21.58.58.93 1.41.93 2.28v.03c0 2.05-1.53 3.23-3.6 3.51l4.06 5.25h-1.16l-3.92-5.09h-4.02v5.09h-.91V44.02Zm5.06 6.38c2.05 0 3.58-1.04 3.58-2.81v-.04c0-1.65-1.3-2.69-3.53-2.69h-4.2v5.53h4.15Zm12.91-6.38h8.74v.84h-7.83v4.83h7.04v.85h-7.04v4.93h7.92v.84h-8.83V44.03zm17.03 0h4.46c2.7 0 4.57 1.39 4.57 3.76v.04c0 2.58-2.23 3.92-4.79 3.92h-3.32v4.58h-.91V44.04Zm4.29 6.87c2.28 0 3.83-1.19 3.83-3.02v-.04c0-1.95-1.51-2.97-3.72-2.97h-3.48v6.02h3.37Zm12.15-.69v-.04c0-3.35 2.48-6.36 6.2-6.36s6.16 2.97 6.16 6.32v.03c0 3.35-2.48 6.36-6.2 6.36s-6.16-2.97-6.16-6.32m11.42 0v-.04c0-3.04-2.21-5.53-5.25-5.53s-5.22 2.46-5.22 5.5v.03c0 3.04 2.21 5.53 5.25 5.53s5.22-2.46 5.22-5.5m9.32-6.16h5.16c1.51 0 2.72.47 3.46 1.21.58.58.93 1.41.93 2.28v.03c0 2.05-1.53 3.23-3.6 3.51l4.06 5.25h-1.16l-3.92-5.09h-4.02v5.09h-.91V44.02Zm5.06 6.38c2.05 0 3.58-1.04 3.58-2.81v-.04c0-1.65-1.3-2.69-3.53-2.69h-4.2v5.53h4.14Zm16.13-5.54h-4.28v-.84H282v.84h-4.29v11.45h-.91V44.87z"
    />
    <path
      d="m50.17 56.21-8.32 8.32c-3.42 3.42-9 3.42-12.42 0l-8.32-8.32c-3.42-3.42-3.42-9.01 0-12.42l8.32-8.32c3.42-3.42 9.01-3.42 12.42 0l8.32 8.32c3.42 3.42 3.42 9.01 0 12.42"
      style={{
        fill: "#1e1e1c",
      }}
    />
    <path
      className="cls-1"
      d="M35.63 67.63c-2.5 0-4.84-.97-6.59-2.72l-8.32-8.32C18.97 54.84 18 52.5 18 50s.97-4.84 2.72-6.59l8.32-8.32c1.75-1.75 4.09-2.72 6.59-2.72s4.84.97 6.59 2.72l8.32 8.32c1.75 1.75 2.72 4.09 2.72 6.59s-.97 4.84-2.72 6.59l-8.32 8.32a9.256 9.256 0 0 1-6.59 2.72Zm0-34.19c-2.21 0-4.28.85-5.83 2.41l-8.32 8.32c-1.55 1.55-2.41 3.62-2.41 5.83s.85 4.28 2.41 5.83l8.32 8.32c1.55 1.55 3.62 2.41 5.83 2.41s4.28-.85 5.83-2.41l8.32-8.32c1.55-1.55 2.41-3.62 2.41-5.83s-.85-4.28-2.41-5.83l-8.32-8.32a8.19 8.19 0 0 0-5.83-2.41Z"
    />
    <path
      className="cls-1"
      d="M45.53 47.41c.72.72 1.13 1.67 1.14 2.69 0 1.02-.38 1.98-1.1 2.7a3.727 3.727 0 0 1-3.38 1.06c-.08-.01-.15-.03-.22-.05-.16-.04-.31-.09-.44-.14-.07-.03-.15-.06-.22-.09-.13-.06-.27-.13-.43-.23-.03-.02-.06-.03-.09-.05-.02 0-.04-.02-.05-.03l-.65-.55c-.07-.08-.15-.17-.22-.27l-.03-.04s-.04-.05-.07-.08l-.02-.03-.02-.03-.02-.02-.13-.13c-1.04-1.03-2.51-1.6-4.02-1.57-1.51.03-2.94.66-3.93 1.73l-.03.03-.03.04s-.05.07-.08.1c-.03.04-.06.07-.09.11l-.05.07-.14.17-.64.56-.06.03-.05.03c-.14.09-.28.17-.44.24-.08.04-.15.07-.22.09-.15.06-.3.11-.45.15-.07.02-.13.04-.21.05-.27.05-.49.08-.69.08-1.02 0-1.98-.38-2.7-1.1a3.777 3.777 0 0 1-1.14-2.69 3.83 3.83 0 0 1 3.79-3.84c.21 0 .43.02.69.07.08.01.14.03.21.05.15.04.3.08.45.13.08.03.15.06.22.09.14.06.28.14.43.23l.08.05.06.03.65.56c.07.07.13.16.2.25l.05.06s.04.05.07.08l.02.02.03.04.02.02.13.13c1.04 1.03 2.51 1.6 4.02 1.57 1.51-.03 2.94-.66 3.93-1.73l.03-.03.03-.04s.05-.07.08-.1c.03-.04.06-.07.09-.11.02-.02.05-.06.08-.1.04-.05.07-.09.11-.13h.01l.62-.55c.01 0 .1-.06.13-.07.15-.1.3-.17.43-.24.07-.04.14-.06.22-.09.15-.06.3-.1.45-.15.07-.02.14-.03.22-.05a3.779 3.779 0 0 1 3.38 1.03Z"
    />
    <path
      className="cls-1"
      d="M35.63 47.28c-1.02 0-1.98-.4-2.7-1.12-.72-.72-1.12-1.68-1.12-2.7s.4-1.98 1.12-2.69c.72-.72 1.68-1.12 2.7-1.12s1.98.4 2.69 1.12c.72.72 1.12 1.68 1.12 2.7s-.4 1.98-1.12 2.7c-.72.72-1.68 1.12-2.69 1.12m0 13.05c-1.02 0-1.98-.4-2.7-1.12-.72-.72-1.12-1.68-1.12-2.7s.4-1.98 1.12-2.69c.72-.72 1.68-1.12 2.7-1.12s1.98.4 2.69 1.12c.72.72 1.12 1.68 1.12 2.7s-.4 1.98-1.12 2.7c-.72.72-1.68 1.12-2.69 1.12"
    />
  </svg>
);
export default SVGComponent;
