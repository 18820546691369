import * as React from "react";
const PolygonIcon = (props) => (
  <svg
    width={156}
    height={139}
    viewBox="0 0 156 139"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M100.316 50.127 55.391 77.213a5.3 5.3 0 0 1-2.716.752 5.3 5.3 0 0 1-2.717-.752l-14.386-8.644a5.564 5.564 0 0 1-1.959-2.082 5.86 5.86 0 0 1-.706-2.822V46.377a5.848 5.848 0 0 1 .73-2.832 5.555 5.555 0 0 1 1.987-2.073l14.345-8.643a5.3 5.3 0 0 1 2.716-.753 5.3 5.3 0 0 1 2.716.753l14.345 8.643a5.555 5.555 0 0 1 1.988 2.073c.477.86.728 1.837.729 2.832v8.294a1.417 1.417 0 0 0 .674 1.223 1.316 1.316 0 0 0 1.353.009l7.47-4.502c.411-.249.752-.607.989-1.037.237-.43.36-.919.358-1.415v-9.112c0-.993-.25-1.968-.725-2.83a5.569 5.569 0 0 0-1.98-2.075L55.349 19.761A5.25 5.25 0 0 0 52.633 19a5.25 5.25 0 0 0-2.717.761l-25.2 15.172a5.557 5.557 0 0 0-1.987 2.073A5.857 5.857 0 0 0 22 39.838v30.366c0 .995.252 1.971.729 2.832a5.557 5.557 0 0 0 1.987 2.073l25.2 15.172a5.25 5.25 0 0 0 2.717.761 5.25 5.25 0 0 0 2.716-.76l44.926-27.086a5.298 5.298 0 0 1 2.716-.753 5.3 5.3 0 0 1 2.716.753l14.345 8.643a5.553 5.553 0 0 1 1.988 2.073 5.86 5.86 0 0 1 .729 2.832v17.287a5.86 5.86 0 0 1-.729 2.831 5.553 5.553 0 0 1-1.988 2.074l-14.303 8.643a5.3 5.3 0 0 1-2.716.753c-.953 0-1.889-.26-2.717-.753l-14.344-8.643a5.555 5.555 0 0 1-1.988-2.074 5.857 5.857 0 0 1-.729-2.831v-8.306a1.452 1.452 0 0 0-.179-.706 1.377 1.377 0 0 0-.495-.518 1.306 1.306 0 0 0-1.353.003l-7.47 4.491c-.41.25-.751.608-.988 1.038a2.93 2.93 0 0 0-.36 1.415v9.122c.001.993.251 1.969.726 2.83a5.569 5.569 0 0 0 1.98 2.075l25.2 15.172a5.25 5.25 0 0 0 2.717.761c.954 0 1.891-.262 2.716-.761l25.2-15.172a5.552 5.552 0 0 0 1.988-2.073 5.859 5.859 0 0 0 .729-2.832V70.204a5.86 5.86 0 0 0-.729-2.832 5.554 5.554 0 0 0-1.988-2.073l-25.2-15.172a5.248 5.248 0 0 0-2.716-.76c-.954 0-1.891.262-2.717.76Z"
      fill="url(#a)"
    />
    <defs>
      <linearGradient
        id="a"
        x1={23.147}
        y1={68.955}
        x2={133.667}
        y2={69.072}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
    </defs>
  </svg>
);
export default PolygonIcon;
