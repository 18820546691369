import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import Div from "../Div";

export default function SocialWidget() {
  return (
    <Div className="cs-social_btns cs-style1">
      <Link to="/" target="_blank" className="cs-center">
        <Icon icon="fa6-brands:linkedin-in" />
      </Link>
      <Link
        to="https://twitter.com/CRIPTOVISION1"
        target="_blank"
        className="cs-center"
      >
        <Icon icon="fa6-brands:twitter" />
      </Link>
      <Link
        to="https://www.youtube.com/c/CRIPTOVISION"
        target="_blank"
        className="cs-center"
      >
        <Icon icon="fa6-brands:youtube" />
      </Link>
      <Link
        to="https://www.instagram.com/criptovisionoficial/"
        target="_blank"
        className="cs-center"
      >
        <Icon icon="fa6-brands:instagram" />
      </Link>
      <Link
        to="https://t.me/criptovision"
        target="_blank"
        className="cs-center"
      >
        <Icon icon="fa6-brands:telegram" />
      </Link>
    </Div>
  );
}
