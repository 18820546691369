import React from "react";
import parse from "html-react-parser";
import "./hero.scss";
import Button from "../Button";
import Div from "../Div";
import VerticalLinks from "../VerticalLinks";

export default function Hero({
  title,
  subtitle,
  btnText,
  btnLink,
  scrollDownId,
  socialLinksHeading,
  heroSocialLinks,
  bgImageUrl,
}) {
  return (
    <Div
      className="cs-hero cs-style1 cs-bg cs-fixed_bg cs-shape_wrap_1"
      style={{ backgroundImage: `url(${bgImageUrl})` }}
    >
      <Div className="cs-shape_1" />
      <Div className="cs-shape_1" />
      <Div className="cs-shape_1" />
      <Div className="container">
        <Div className="cs-hero_text">
          <h1 className="cs-hero_title">{parse(title)}</h1>
          <Div className="cs-hero_info">
            <Div>
              <Div>
                <Button
                  target="_blank"
                  btnLink="https://bscscan.com/address/0x4bbd4fa12b2b874a13e9555f5c5d0f6ad035acc3"
                  btnText="VSION Smart Contract"
                />
              </Div>{" "}
              <br />
              <Div>
                {" "}
                <Button
                  target="_blank"
                  btnLink="https://pancakeswap.finance/swap?outputCurrency=0x4BBD4fa12b2B874A13e9555F5C5d0F6aD035ACc3"
                  btnText="Buy VSION"
                />
              </Div>
              <br />{" "}
              <Div>
                {" "}
                <Button
                  target="_blank"
                  btnLink="https://app.vsion.io"
                  btnText="Go to DAPP"
                />
              </Div>
              <br />{" "}
              <Div>
                {" "}
                <Button
                  target="_blank"
                  btnLink="https://criptovision.com/docs/INFORME_AUDITORIA_MINERY_REPORT.pdf"
                  btnText="Audit"
                />
              </Div>
            </Div>

            <Div>
              {" "}
              <div
                style={{
                  backgroundImage: `url('/images/Oxy_Astronaut_290724.png')`,
                  width: "380px",
                  height: "350px",
                  backgroundPosition: "center center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
            </Div>
          </Div>
        </Div>
      </Div>
      <VerticalLinks data={heroSocialLinks} title={socialLinksHeading} />
      <a href={scrollDownId} className="cs-down_btn">
        .
      </a>
    </Div>
  );
}
