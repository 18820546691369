import * as React from "react";
const BinanceIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={135}
    height={140}
    viewBox="0 0 135 140"
    fill="none"
    {...props}
  >
    <path
      d="m41.523 58.624 26.132-27.092L93.8 58.639l15.206-15.765L67.655 0 26.318 42.86l15.205 15.764Zm-10.738 11.13L15.58 53.989.375 69.755l15.204 15.764 15.206-15.765Zm36.87 38.226L41.523 80.888 26.295 96.632l41.36 42.88 41.351-42.872.007-.01-15.214-15.755-26.144 27.105Zm52.076-22.454 15.205-15.765-15.205-15.764-15.205 15.765 15.205 15.764Z"
      fill="url(#a)"
    />
    <path
      d="M83.084 69.748h-.006l.014.008-.008.009-15.43 15.997L52.24 69.78l-.021-.023 15.436-16.005 15.43 15.997Z"
      fill="url(#b)"
    />
    <defs>
      <linearGradient
        id="a"
        x1={1.757}
        y1={68.055}
        x2={134.938}
        y2={68.18}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={52.535}
        y1={69.366}
        x2={83.092}
        y2={69.395}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
    </defs>
  </svg>
);
export default BinanceIcon;
