import React, { useEffect } from "react";
import Card from "../Card";
import FunFact from "../FunFact";
import Hero from "../Hero";
import Div from "../Div";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import Cta from "../Cta";
import MovingText from "../MovingText";
import PostSlider from "../Slider/PostSlider";
import TeamSlider from "../Slider/TeamSlider";
import VideoModal from "../VideoModal";
import { pageTitle } from "../../helper";
import CaseStudiesSlider from "../Slider/CaseStudiesSlider";
import PolygonIcon from "../PlatformsIcon/Polygon";
import EthereumIcon from "../PlatformsIcon/Ethereum";
import BinanceIcon from "../PlatformsIcon/Binance";
import TronIcon from "../PlatformsIcon/Tron";
import PartnersSlider from "../Slider/PartnersSlider";

export default function Home() {
  pageTitle("Home");

  // Hero Social Links
  const heroSocialLinks = [
    {
      name: "Telegram",
      links: "https://t.me/criptovision",
    },
    {
      name: "Twitter",
      links: "https://twitter.com/CRIPTOVISION1",
    },
    {
      name: "Instagram",
      links: "https://www.instagram.com/criptovisionoficial/",
    },
  ];

  // FunFact Data
  const funfaceData = [
    {
      title: "Initiation",
      factNumber: "",
    },
    {
      title: "Discovery",
      factNumber: "",
    },
    {
      title: "Architecture & Design",
      factNumber: "",
    },
    {
      title: "Development",
      factNumber: "",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* Start Hero Section */}
      <Hero
        title="End-to-end<br/> Blockchain Services"
        subtitle="Providing a wide scope of web 3.0 services."
        btnText="Get a Quote"
        btnLink="https://t.me/criptovision1"
        scrollDownId="#service"
        socialLinksHeading="Follow Us"
        heroSocialLinks={heroSocialLinks}
        bgImageUrl="/images/hero_bg.jpeg"
      />
      {/* End Hero Section */}
      {/* Start FunFact Section */}
      <div className="container">
        <FunFact
          variant="cs-type1"
          title={
            <>
              We build
              <br />
              Your emotions
            </>
          }
          data={funfaceData}
        />
      </div>
      {/* End FunFact Section */}
      <Spacing lg="130" md="70" />
      <Div className="container">
        <h2 className="cs-font_50 cs-m0 text-center cs-line_height_4">
          Your ideas and emotions can come true with us! <br />
          Have a big idea? Let's start with a demo pilot! <br />
          Start building your project now!
        </h2>
        <Spacing lg="70" md="70" />
        <VideoModal
          videoSrc="https://www.youtube.com/watch?v=gZyfXY18nzs"
          bgUrl="/images/video_bg.png"
        />
      </Div>
      {/* Start Service Section */}
      <Spacing lg="150" md="80" />
      <Div id="service">
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Services we can help you with"
                subtitle="What Can We Do"
                btnText="Apply For Meeting"
                btnLink="https://t.me/criptovision1"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-8">
              <Div className="row">
                {/* <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div> */}
                <Div className="col-lg-4 col-sm-6 ">
                  <Card
                    title="SMART CONTRACT"
                    link="/"
                    src="/images/service_1.jpeg"
                    alt="Service"
                  />
                  <Spacing lg="30" md="30" />
                </Div>
                <Div className="col-lg-4 col-sm-6">
                  <Card
                    title="DAPP DEVELOPMENT"
                    link="/"
                    src="/images/service_1.jpeg"
                    alt="Service"
                  />
                  <Spacing lg="30" md="30" />
                </Div>
                <Div className="col-lg-4 col-sm-6">
                  <Card
                    title="WEBSITE DEVELOPMENT"
                    link="/"
                    src="/images/service_1.jpeg"
                    alt="Service"
                  />
                  <Spacing lg="30" md="30" />
                </Div>
                <Div className="col-lg-4 col-sm-6">
                  <Card
                    title="NFT AND TOKEN"
                    link="/"
                    src="/images/service_1.jpeg"
                    alt="Service"
                  />
                  <Spacing lg="30" md="30" />
                </Div>
                {/* <Div className="col-lg-4 col-sm-6 cs-hidden_mobile"></Div> */}
                <Div className="col-lg-4 col-sm-6">
                  <Card
                    title="ANDROID AND IOS APP"
                    link="/"
                    src="/images/service_2.jpeg"
                    alt="Service"
                  />
                  <Spacing lg="30" md="30" />
                </Div>
                <Div className="col-lg-4 col-sm-6">
                  <Card
                    title="MARKETPLACES"
                    link="/"
                    src="/images/service_3.jpeg"
                    alt="Service"
                  />
                  <Spacing lg="30" md="30" />
                </Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* End Service Section */}
      {/* Start Our Case Studies Section */}
      <Spacing lg="150" md="80" />
      <Div id="Our-Case-Studies">
        <Div className="container">
          <Div className="cs-funfact_left">
            <Div className="cs-funfact_heading">
              <h2>Our Case Studies</h2>
              <p>
                Real-world success stories revealed- Dive into our successful
                blockchain projecs and case studies.
              </p>
            </Div>
          </Div>
          <Spacing lg="85" md="45" />
          <CaseStudiesSlider />
        </Div>
      </Div>
      <Spacing lg="150" md="80" />
      <Div id="Platforms">
        <Div className="container">
          <Div className="cs-funfact_left">
            <Div className="cs-funfact_heading">
              <h2>Platforms We Work On</h2>
            </Div>
          </Div>
          <Spacing lg="50" md="45" />
          <Div className="row">
            <Div className="col-lg-3 col-sm-6 ">
              <Div className="cs-funfact_heading text-center">
                <EthereumIcon />
                <h4>Ethereum</h4>
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6 ">
              <Div className="cs-funfact_heading text-center">
                <BinanceIcon />
                <h4>Binance</h4>
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6 ">
              <Div className="cs-funfact_heading text-center">
                <PolygonIcon />
                <h4>Polygon</h4>
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6 ">
              <Div className="cs-funfact_heading text-center">
                <TronIcon />
                <h4>Tron</h4>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>

      <Spacing lg="150" md="80" />

      <Div className="cs-shape_wrap_4" id="token">
        <Div className="cs-shape_4"></Div>

        <Div className="container">
          <Div className="row">
            <SectionHeading
              title="   <p>Total Supply: 1,000,000,000.00</p>
              <p>VISION Name: CRIPTOVISION TOKEN</p>
              <p>Symbol: VSION</p>"
              subtitle="Vsion Token"
              variant="cs-style1"
            />

            <Div className="col-xl-12">
              {" "}
              <Cta
                title="Utility token with multiple functions to harmonize the entire
                CRIPTOVISION ecosystem as a DAO."
                btnText="Buy VSION"
                btnLink="https://pancakeswap.finance/swap?outputCurrency=0x4BBD4fa12b2B874A13e9555F5C5d0F6aD035ACc3"
                bgSrc="/images/team_hero_bg.jpeg"
              />
            </Div>
          </Div>
          <Div className="row">
            <Div className="col-xl-4">
              <Spacing lg="90" md="45" />
            </Div>
          </Div>
        </Div>
      </Div>
      <Div className="container">
        <SectionHeading
          title="A great Team can <br/>do Great Things"
          subtitle="Our Team"
          variant="cs-style1"
        />
        <Spacing lg="85" md="45" />
        <TeamSlider />
      </Div>
      {/* End Blog Section */}
      {/* Start MovingText Section */}
      <Spacing lg="125" md="70" />
      <MovingText text="Decentralized Blockchain Web 3.0  Technology Gaming  -  Tokens no fungibles (NFTs) Develop DAO " />
      <Spacing lg="105" md="70" />
      {/* End MovingText Section */}
      {/* Start LogoList Section */}
      <Spacing lg="105" md="70" />
      <Div className="container">
        <PartnersSlider />
      </Div>
      <Spacing lg="150" md="80" />
      {/* End LogoList Section */}
      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Have an idea <br /> for a <i>Web3</i> project?"
          btnText="Apply For Meeting"
          btnLink="https://t.me/criptovision"
          bgSrc="/images/slider_1.jpeg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}
