import * as React from "react";
const EthereumIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={156}
    height={139}
    viewBox="0 0 156 139"
    fill="none"
    {...props}
  >
    <path
      d="M115.15 69.6v-.2c0-.1 0-.1-.096-.2V69c0-.1-.095-.1-.095-.2 0 0 0-.1-.096-.1L79.205 7c-.096-.1-.192-.3-.288-.4h-.096c-.096-.1-.192-.2-.287-.2 0 0-.096 0-.096-.1-.096-.1-.192-.1-.288-.1h-.096c-.191-.2-.287-.2-.479-.2s-.287 0-.48.1H77c-.096 0-.192.1-.288.1 0 0-.095 0-.095.1-.096.1-.192.1-.288.2h-.096c-.096.1-.192.2-.287.4L40.288 68.7s0 .1-.096.1c0 .1-.096.1-.096.2v.2c0 .1 0 .1-.096.2v.8c0 .1 0 .1.096.2v.1c.096.2.192.3.287.4l.096.1.288.3s.096 0 .096.1l35.754 21.8h.095c.096.1.192.1.288.1h.096c.192 0 .287.1.48.1.19 0 .287 0 .478-.1h.096c.096 0 .192-.1.288-.1h.096l35.658-21.7c.096 0 .096-.1.191-.1l.096-.1.192-.2.096-.1c0-.1.096-.1.096-.2s.096-.1.096-.2 0-.1.095-.2c0-.1 0-.1.096-.2v-.6Zm-39.492-18L46.806 65.3l28.852-50.1v36.4Zm0 4.4v32L46.135 70l29.523-14Zm3.834 0 29.524 14-29.524 18V56Zm0-4.4V15.2l28.853 50.1-28.853-13.7Z"
      fill="url(#a)"
    />
    <path
      d="M112.179 78 77.575 99.7 42.972 78c-.767-.5-1.822-.3-2.397.4-.575.7-.67 1.7-.192 2.5l35.658 52.2.096.1c.096.1.096.2.192.2l.096.1c.096.1.192.1.192.2 0 0 .096 0 .096.1.095 0 .191.1.287.1h.096c.096 0 .287.1.383.1.192 0 .288 0 .384-.1h.096c.096 0 .191-.1.287-.1 0 0 .096 0 .096-.1.096 0 .192-.1.192-.2l.096-.1.191-.2.096-.1 35.658-52.2c.479-.8.479-1.8-.191-2.5-.671-.7-1.342-.8-2.205-.4Zm-36.52 25.1v22.6l-26.84-39.3 26.84 16.7Zm3.833 22.6v-22.6l26.84-16.8-26.84 39.4Z"
      fill="url(#b)"
    />
    <defs>
      <linearGradient
        id="a"
        x1={40.772}
        y1={48.634}
        x2={115.151}
        y2={48.696}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={40.846}
        y1={105.175}
        x2={114.914}
        y2={105.27}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
    </defs>
  </svg>
);
export default EthereumIcon;
