import { Icon } from "@iconify/react";
import React from "react";
import Slider from "react-slick";
import Div from "../Div";
import Team from "../Team";

export default function TeamSlider() {
  /** Team Member Data **/

  /*


-	Doris Trillos 		- CFO (key investor)
-	Brenda Chacón 		- CSO
-	Juan Carlos Albújar 	- CCO
-	Keylord Peña 		- CMO
-	Fredy Teheran 		- CTO
- 	Freddy Menéndez 	- CAO
-	Viviana Castro 		- CM & Press
-	Daniel Meza 		- Arts & Design 	
- 	Jhenny Martínez	- Social Worker
-	Marleny Huamán 	- Mkt & Desing
- 	Max Salazar 		- Video Producer
-	Neyro Gómez 		- Promoter
- 	Jonatan Villegas 	- Promoter
-	Yuriko Palomino 	- Promoter

 */
  const teamData = [
    {
      memberImage: "/images/avatar.webp",
      memberName: "Nelson Cabrera",
      memberDesignation: "CEO (& main investor)",
    },
    {
      memberImage: "/images/avatar.webp",
      memberName: "José Zárate ",
      memberDesignation: "IT advisor",
    },
    {
      memberImage: "/images/avatar.webp",
      memberName: "Fredy Teheran",
      memberDesignation: "CTO",
    },
    {
      memberImage: "/images/avatar.webp",
      memberName: "Miguel Cabrera ",
      memberDesignation: "Legal Advisor",
    },
    {
      memberImage: "/images/avatar.webp",
      memberName: "Javier Haro",
      memberDesignation: "HOT (key investor)",
    },
    {
      memberImage: "/images/avatar.webp",
      memberName: "Doris Trillos",
      memberDesignation: "CFO (key investor)",
    },
    {
      memberImage: "/images/avatar.webp",
      memberName: "Brenda Chacón",
      memberDesignation: "CSO",
    },
    {
      memberImage: "/images/avatar.webp",
      memberName: "Juan Carlos Albújar  ",
      memberDesignation: "CCO",
    },
    {
      memberImage: "/images/avatar.webp",
      memberName: "Keylord Peña",
      memberDesignation: "CMO",
    },
    {
      memberImage: "/images/avatar.webp",
      memberName: "Freddy Menéndez",
      memberDesignation: "CAO",
    },
    {
      memberImage: "/images/avatar.webp",
      memberName: "Viviana Castro ",
      memberDesignation: "CM & Press",
    },
    {
      memberImage: "/images/avatar.webp",
      memberName: "Jhenny Martínez",
      memberDesignation: "Social Worker",
    },
    {
      memberImage: "/images/avatar.webp",
      memberName: "Marleny Huamán",
      memberDesignation: "Mkt & Desing",
    },
    {
      memberImage: "/images/avatar.webp",
      memberName: "Max Salazar ",
      memberDesignation: "Video Producer",
    },
    {
      memberImage: "/images/avatar.webp",
      memberName: "Neyro Gómez ",
      memberDesignation: "Promoter",
    },

    {
      memberImage: "/images/avatar.webp",
      memberName: "Yuriko Palomino",
      memberDesignation: "Promoter",
    },
    // Neyro Gómez
  ];
  /** Slider Settings **/
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
    >
      <Icon icon="bi:arrow-left" />
    </div>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
    >
      <Icon icon="bi:arrow-right" />
    </div>
  );
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Slider {...settings} className="cs-gap-24 cs-arrow_style2">
      {teamData.map((item, index) => (
        <Div key={index}>
          <Team
            memberImage={item.memberImage}
            memberName={item.memberName}
            memberDesignation={item.memberDesignation}
          />
        </Div>
      ))}
    </Slider>
  );
}
