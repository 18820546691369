import React from "react";
import { Icon } from "@iconify/react";

export default function ContactInfoWidget({ withIcon, title }) {
  return (
    <>
      {title && <h2 className="cs-widget_title">{title}</h2>}
      <ul className="cs-menu_widget cs-style1 cs-mp0">
        <li>
          {withIcon ? (
            <span className="cs-accent_color">
              <Icon icon="material-symbols:add-call-rounded" />
            </span>
          ) : (
            ""
          )}
          🇵🇪 +51 935677637
        </li>
        <li>
          {withIcon ? (
            <span className="cs-accent_color">
              <Icon icon="material-symbols:add-call-rounded" />
            </span>
          ) : (
            ""
          )}
          🇨🇴 +57 323 8223951
        </li>
        <li>
          {withIcon ? (
            <span className="cs-accent_color">
              <Icon icon="material-symbols:add-call-rounded" />
            </span>
          ) : (
            ""
          )}
          🇦🇪 +971 50 329 6773
        </li>
        <li>
          {withIcon ? (
            <span className="cs-accent_color">
              <Icon icon="mdi:envelope" />
            </span>
          ) : (
            ""
          )}
          consultas@criptovision.com
        </li>
        <li>
          <a
            href="https://www.google.com/maps/search/dubai+silicon+oasis+ddp+building+a1+dubai+united+arab+emirates/@25.124801,55.3712008,16z?entry=ttu"
            target="_blank"
            rel="noreferrer"
          >
            🇦🇪 Dubai Silicon Oasis, DDP, Building A1, Dubai, United Arab
            Emirates
          </a>
        </li>
        <li>
          <a
            href="https://www.bing.com/maps?where=Lima%2C%20Lima%20Lima33%2C%20PE"
            target="_blank"
            rel="noreferrer"
          >
            🇵🇪 Calle Las Orquídeas 585, piso 13, San Isidro, Lima
          </a>
        </li>
      </ul>
    </>
  );
}
