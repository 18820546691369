import React from "react";
import Div from "../Div";
import ContactInfoWidget from "../Widget/ContactInfoWidget";
import MenuWidget from "../Widget/MenuWidget";
import SocialWidget from "../Widget/SocialWidget";
import TextWidget from "../Widget/TextWidget";
import "./footer.scss";

export default function Footer({ copyrightText, logoSrc, logoAlt, text }) {
  const copyrightLinks = [
    {
      title: "Terms of Use",
      href: "https://whitepaper.vsion.io/legal/terms-of-use",
    },

    {
      title: "Privacy Policy",
      href: "https://whitepaper.vsion.io/legal/privacy-policy",
    },
  ];

  const serviceMenu = [
    {
      title: "SMART CONTRACT",
      href: "https://bscscan.com/address/0x4BBD4fa12b2B874A13e9555F5C5d0F6aD035ACc3#code",
    },
    {
      title: "AUDIT",
      href: "https://criptovision.com/docs/INFORME_AUDITORIA_MINERY_REPORT.pdf",
    },
    {
      title: "Vsion Token",
      href: "#token",
    },
    {
      title: "BUY VISION",
      href: "https://pancakeswap.finance/swap?outputCurrency=0x4BBD4fa12b2B874A13e9555F5C5d0F6aD035ACc3",
    },
  ];

  return (
    <footer className="cs-fooer">
      <Div className="cs-fooer_main">
        <Div className="container">
          <Div className="row">
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <TextWidget
                  logoSrc="/images/footer_logo.svg"
                  logoAlt="Logo"
                  text="Cryptovision stands at the forefront of innovation as a leading software development lab specializing in tailor-made software solutions, Web3 development, and blockchain technology."
                />
                <SocialWidget />
              </Div>
            </Div>
            <Div className="col-lg-1 "></Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <MenuWidget menuItems={serviceMenu} menuHeading="Services" />
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <ContactInfoWidget title="Contact Us" />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Div className="container">
        <Div className="cs-bottom_footer">
          <Div className="cs-bottom_footer_left">
            <Div className="cs-copyright">
              Copyright © {new Date().getFullYear()} Criptovision. All rights
              reserved.
            </Div>
          </Div>
          <Div className="cs-bottom_footer_right">
            <MenuWidget menuItems={copyrightLinks} variant=" cs-style2" />
          </Div>
        </Div>
      </Div>
    </footer>
  );
}
